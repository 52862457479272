<template>
    <div>
        <h1 class="title is-4">
            {{ $t("Interface.IronLogic.Delete.Title") }}
        </h1>
        <div class="box">
            <h5 class="subtitle is-5">{{ $t("Interface.IronLogic.Delete.Text") }}</h5>
            <article class="message is-danger">
                <div class="message-body">
                    <h6 class="title is-6">{{ $t("Interface.IronLogic.Name") }}</h6>
                    <p>{{ Form.Name }}</p>
                    <h6 class="title is-6">{{ $t("Interface.IronLogic.Serial") }}</h6>
                    <p>{{ Form.Serial }}</p>
                    <h6 class="title is-6">{{ $t("Interface.IronLogic.Login") }}</h6>
                    <p>{{ Form.Login }}</p>
                    <h6 class="title is-6">{{ $t("Interface.Door.Address") }}</h6>
                    <p>{{ Form.Address }}</p>
                </div>
            </article>
            <b-button
                type="submit"
                class="button is-theme is-danger"
                :loading="IsLoading"
                @click.prevent="submit"
            >
                {{ $t("Interface.Button.Delete") }}
            </b-button>
        </div>
    </div>
</template>

<script>
import LoadingState from "@/mixins/LoadingState"
import {
    IRONLOGIC_CONTROLLER_LIST_REQUEST,
    IRONLOGIC_CONTROLLER_GET_REQUEST,
    IRONLOGIC_CONTROLLER_DELETE_REQUEST
} from "@/store/actions/ironlogic"

export default {
    name: "IronLogicDelete",
    mixins: [LoadingState],
    data() {
        return {
            Form: {
                Serial: null,
                Name: null,
                Address: null,
                Login: null
            }
        }
    },
    mounted() {
        if (this.$route.params.id && typeof (this.$route.params.id) !== "undefined") {
            this.switchLoading()
            this.$store.dispatch(IRONLOGIC_CONTROLLER_GET_REQUEST, this.$route.params.id)
                .then((response) => this.getData(response.data))
                .finally(() => this.switchLoading())
        }
        else this.$router.push({ name: "IronLogic-List" })
    },
    methods: {
        getData(data) {
            this.Form.Serial = data.serial
            this.Form.Name = data.name
            this.Form.Address = data.address
            this.Form.Login = data.login
        },
        submit() {
            this.switchLoading()
            this.$store.dispatch(IRONLOGIC_CONTROLLER_DELETE_REQUEST, this.$route.params.id)
                .then(() => {
                    this.$store.dispatch(IRONLOGIC_CONTROLLER_LIST_REQUEST)
                        .then(() => this.$router.push({ name: "IronLogic-List" }))
                })
                .finally(() => this.switchLoading())
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.IronLogic.Delete.Title")
        }
    }
}
</script>

<style scoped>
h6.title {
    margin-bottom: 0;
}
.message {
    box-shadow: none;
}
.message-body {
    border-radius: 0;
}
</style>
